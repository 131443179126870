import SecureLS from "secure-ls";

const ls = new SecureLS({ encodingType: "aes", isCompression: true });

const i18nService = {
  defaultLanguage: "tr",

  languages: [
    {
      lang: "tr",
      name: "TR",
      flag: process.env.VUE_APP_MEDIA_URL + "/images/flags/218-turkey.svg",
    },
    {
      lang: "en",
      name: "EN",
      flag:
        process.env.VUE_APP_MEDIA_URL + "/images/flags/226-united-states.svg",
    },
    {
      lang: "az",
      name: "AZ",
      flag: process.env.VUE_APP_MEDIA_URL + "/images/flags/141-azerbaijan.svg",
    },
    {
      lang: "de",
      name: "DE",
      flag: process.env.VUE_APP_MEDIA_URL + "/images/flags/de.png",
    },
    {
      lang: "kz",
      name: "KZ",
      flag: process.env.VUE_APP_MEDIA_URL + "/images/flags/kz.png",
    },
    {
      lang: "ro",
      name: "RO",
      flag: process.env.VUE_APP_MEDIA_URL + "/images/flags/ro.png",
    },
    {
      lang: "ru",
      name: "RU",
      flag: process.env.VUE_APP_MEDIA_URL + "/images/flags/ru.png",
    },
  ],

  /**
   * Keep the active language in the localStorage
   * @param lang
   */
  setActiveLanguage(lang) {
    ls.set("language", lang);
  },

  /**
   * Get the current active language
   * @returns {string | string}
   */
  getActiveLanguage() {
    return ls.get("language") ? ls.get("language") : this.defaultLanguage;
  },
};

export default i18nService;
