import Vue from "vue";
import Vuex from "vuex";

// Modules
import auth from "./store/auth.module";
import rest from "./store/REST.module";
import test from "./store/test/test.module"
import educationDetail from "@/core/services/store/education/educationDetail.module"

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        auth,
        rest,
        test,
        educationDetail
    }
});

