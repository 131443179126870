import ApiService from "@/core/services/api.service";
import router from "@/router";
import { responseError } from "@/core/mixin/shared";
import querystring from "querystring";

const BASE = "REST/CRUD";

export const LOADING = `${BASE}/LOADING`;
export const ERROR = `${BASE}/ERROR`;
export const SUCCESS = `${BASE}/SUCCESS`;
export const ITEMS = `${BASE}/ITEMS`;
export const COUNTS = `${BASE}/COUNTS`;
export const CURRENT_ITEM = `${BASE}/CURRENT_ITEM`;

export const SET_LOADING = `${BASE}/M/LOADING`;
export const SET_ERROR = `${BASE}/M/ERROR`;
export const SET_SUCCESS = `${BASE}/M/SUCCESS`;
export const SET_ITEMS = `${BASE}/M/ITEMS`;
export const SET_COUNTS = `${BASE}/M/COUNTS`;
export const SET_CURRENT_ITEM = `${BASE}/M/CURRENT_ITEM`;
export const RESET_VALUES = `${BASE}/M/RESET_VALUES`;

// ACTIONS
export const GET_ITEMS = `${BASE}/GET_ITEMS`;
export const GET_COUNTS = `${BASE}/GET_COUNTS`;
export const GET_ITEM_DETAIL_BY_ID = `${BASE}/GET_ITEM_DETAIL_BY_ID`;
export const CREATE_ITEM = `${BASE}/CREATE_ITEM`;
export const UPDATE_ITEM_BY_ID = `${BASE}/UPDATE_ITEM_BY_ID`;
export const DELETE_ITEM_BY_ID = `${BASE}/DELETE_ITEM_BY_ID`;

const state = {
  items: [],
  counts: {},
  currentItem: null,
  loading: false,
  error: null,
  success: null,
};

const getters = {
  [LOADING]: (state) => {
    return state.loading;
  },
  [ERROR]: (state) => {
    return state.error;
  },
  [SUCCESS]: (state) => {
    return state.success;
  },
  [ITEMS]: (state) => {
    return state.items;
  },
  [COUNTS]: (state) => {
    return state.counts;
  },
  [CURRENT_ITEM]: (state) => {
    return state.currentItem;
  },
};

const mutations = {
  [SET_LOADING]: (state, payload) => {
    state.loading = payload;
  },
  [SET_ERROR]: (state, payload) => {
    state.error = payload ? responseError(payload) : null;
  },
  [SET_SUCCESS]: (state, payload) => {
    state.success = payload;
  },
  [SET_ITEMS]: (state, payload) => {
    state.items = [];

    state.items = payload;
  },
  [SET_COUNTS]: (state, payload) => {
    state.counts = payload;
  },
  [SET_CURRENT_ITEM]: (state, payload) => {
    state.currentItem = payload;
  },
  [RESET_VALUES]: (state) => {
    state.success = null;
    state.error = null;
    state.loading = false;
    state.items = [];
    state.currentItem = null;
  },
};

const actions = {
  [GET_ITEMS]: (context, payload) => {
    let url = payload.url;
    let filters = payload.filters;
    if ("showLoading" in payload && payload.showLoading) {
      context.commit(SET_LOADING, true);
    }
    return ApiService.get(`${url}?` + querystring.stringify(filters))
      .then((response) => {
        if (!payload.acceptPromise) {
          context.commit(SET_ITEMS, response.data);
        }
        if ("showLoading" in payload && payload.showLoading) {
          context.commit(SET_LOADING, false);
        }
        context.commit(SET_ERROR, null);
        return { status: true, data: response.data };
      })
      .catch((error) => {
        context.commit(SET_ERROR, error);
        context.commit(SET_LOADING, false);
        return { status: false, error: error };
      });
  },
  [GET_COUNTS]: (context, payload) => {
    let url = payload.url;
    let filters = payload.filters;
    if ("showLoading" in payload && payload.showLoading) {
      context.commit(SET_LOADING, true);
    }
    return ApiService.get(`${url}?` + querystring.stringify(filters))
      .then((response) => {
        if (!payload.acceptPromise) {
          context.commit(SET_COUNTS, response.data);
        }
        if ("showLoading" in payload && payload.showLoading) {
          context.commit(SET_LOADING, false);
        }
        context.commit(SET_ERROR, null);
        return { status: true, data: response.data };
      })
      .catch((error) => {
        context.commit(SET_ERROR, error);
        context.commit(SET_LOADING, false);
        return { status: false, error: error };
      });
  },
  [GET_ITEM_DETAIL_BY_ID]: (context, payload) => {
    let url = payload.url;
    let filters = payload.filters;
    context.commit(SET_LOADING, true);
    return ApiService.get(`${url}?` + querystring.stringify(filters))
      .then((response) => {
        if (!payload.acceptPromise) {
          context.commit(SET_CURRENT_ITEM, response.data);
        }
        context.commit(SET_LOADING, false);
        context.commit(SET_ERROR, null);
        return { status: true, data: response.data };
      })
      .catch((error) => {
        context.commit(SET_ERROR, error);
        context.commit(SET_LOADING, false);
        router.push({
          name: payload.redirectRouteOnNotFound,
          params: {
            error: "general.couldnt_found_item_with_given_id",
          },
        });
      });
  },
  [CREATE_ITEM]: (context, payload) => {
    let url = payload.url;
    let contents = payload.contents;
    context.commit(SET_LOADING, true);
    return ApiService.post(url, contents)
      .then((response) => {
        if (!payload.acceptPromise) {
          context.commit(SET_CURRENT_ITEM, null);
          context.commit(SET_ITEMS, []);
          router.push({
            name: payload.redirectRouteName,
            params: { success: payload.successMessage },
          });
        }
        context.commit(SET_LOADING, false);
        context.commit(SET_ERROR, null);
        return { status: true, data: response.data };
      })
      .catch((error) => {
        context.commit(SET_ERROR, error);
        context.commit(SET_LOADING, false);
        return {
          status: false,
          error: error.response.data ? error.response.data : error,
          code: error.response.status ? error.response.status : 500,
        };
      });
  },
  [UPDATE_ITEM_BY_ID]: (context, payload) => {
    let url = payload.url;
    let contents = payload.contents;

    if (!payload.hasOwnProperty("noLoading")) {
      context.commit(SET_LOADING, true);
    }
    return ApiService.post(url, contents)
      .then((response) => {
        if (payload.redirectRouteName != undefined) {
          context.commit(SET_LOADING, false);
          context.commit(SET_CURRENT_ITEM, null);
          context.commit(SET_ITEMS, []);
          router.push({
            name: payload.redirectRouteName,
            params: { success: payload.successMessage },
          });
        }
        context.commit(SET_LOADING, false);
        context.commit(SET_ERROR, null);
        return { status: true, data: response.data };
      })
      .catch((error) => {
        context.commit(SET_ERROR, error);
        context.commit(SET_LOADING, false);
        return {
          status: false,
          error: error.response.data ? error.response.data : error,
          code: error.response.status ? error.response.status : 500,
        };
      });
  },
  [DELETE_ITEM_BY_ID]: (context, payload) => {
    let url = payload.url;
    let id = payload.id;
    context.commit(SET_LOADING, true);
    return ApiService.delete(url)
      .then((_) => {
        if (context.state.items.data !== undefined) {
          for (let i = 0; i < context.state.items.data.length; i++) {
            let currentItem = context.state.items.data[i];
            if (Number(id) === Number(currentItem.id)) {
              context.state.items.data.splice(i, 1);
              break;
            }
          }
        } else {
          for (let i = 0; i < context.state.items.length; i++) {
            if (Number(id) === Number(context.state.items[i].id)) {
              context.state.items.splice(i, 1);
              break;
            }
          }
        }
        context.commit(SET_ERROR, null);
        context.commit(SET_LOADING, false);
      })
      .catch((error) => {
        context.commit(SET_ERROR, error);
        context.commit(SET_LOADING, false);
        return {
          status: false,
          error: error.response.data ? error.response.data : error,
          code: error.response.status ? error.response.status : 500,
        };
      });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
