import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./core/services/index";
import ApiService from "./core/services/api.service";
import {
  getTranslations,
  prepareConfig,
} from "./core/services/i18n.Api.Service";
import VueTelInput from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";
import filter from "@/core/filter/index";
import mixin from "@/core/mixin/index";
import SecureLS from "secure-ls";
import VueNotifications from "vue-notifications";

// import * as Sentry from "@sentry/vue";
// import { BrowserTracing } from "@sentry/tracing";

const ls = new SecureLS({ encodingType: "aes", isCompression: true });

import VueSocialSharing from "vue-social-sharing";
Vue.config.productionTip = false;

// Global 3rd party plugins
import "popper.js";
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";
window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from "clipboard";
window.ClipboardJS = ClipboardJS;

// Vue 3rd party plugins
import "@mdi/font/css/materialdesignicons.css";
import VueSweetalert2 from "vue-sweetalert2";
import VuePlyr from "vue-plyr";
import "vue-plyr/dist/vue-plyr.css";
import CoolLightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";
import VModal from "vue-js-modal";
import Multiselect from "vue-multiselect";
import VueTippy from "vue-tippy";
import "tippy.js/themes/light.css";
import "tippy.js/themes/light-border.css";
import "tippy.js/themes/google.css";
import "tippy.js/themes/translucent.css";
import { ToggleButton } from "vue-js-toggle-button";
import VueQRCodeComponent from "vue-qrcode-component";

import $ from "jquery";

Window.$ = $;
import "@/assets/js/framework";
import "@/assets/js/simplebar";
import { CURRENT_ITEM, MODULE_NAME } from "@/core/services/store/user.module";
//import moment from "moment-timezone";

// API service init
ApiService.init();

Vue.use(VueSweetalert2);
Vue.use(filter);
Vue.use(VuePlyr, {
  plyr: {},
});
Vue.use(CoolLightBox);
Vue.use(VModal);
Vue.component("multiselect", Multiselect);
Vue.component("ToggleButton", ToggleButton);
Vue.component("qr-code", VueQRCodeComponent);

Vue.use(VueTippy);
Vue.use(VueTelInput);
// Vue.use(VueSocialSharing);
Vue.use(VueSocialSharing, {
  networks: {
    linked:
      "https://www.linkedin.com/signup/cold-join?session_redirect=https%3A%2F%2Fwww%2Elinkedin%2Ecom%2FshareArticle%2F%3Furl%3Dhttps%253A%252F%252Fnews%2Evuejs%2Eorg%252Fissues%252F180&trk=login_reg_redirect",
  },
});

import iziToast from "izitoast"; // https://github.com/dolce/iziToast
import "izitoast/dist/css/iziToast.min.css";
import VueCtkDateTimePicker from "vue-ctk-date-time-picker";
import "vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css";

Vue.component("VueCtkDateTimePicker", VueCtkDateTimePicker);
var PulseLoader = require("vue-spinner/src/PulseLoader.vue");

new Vue({
  components: {
    PulseLoader: PulseLoader,
  },
});
function toast({ title, message, type, timeout, cb, position }) {
  if (type === VueNotifications.types.warn) type = "warning";
  return iziToast[type]({ title, message, timeout, position });
}

const VueNotificationsOptions = {
  success: toast,
  error: toast,
  info: toast,
  warn: toast,
};

Vue.use(VueNotifications, VueNotificationsOptions);

Vue.config.devtools = true;

Vue.mixin({
  ...mixin,
});

try {
  let version = process.env.VUE_APP_VERSION;
  let localVersion = ls.get("version");

  if (!localVersion || version !== localVersion) {
    // window.localStorage.clear();
    ls.set("version", version);
    // location.reload();
  }
} catch (e) {
  // window.localStorage.clear();
  // location.reload();
}

//moment.tz.setDefault("Europe/Istanbul");

if (process.env.NODE_ENV === "disabled") {
  // Sentry init
  // Sentry.init({
  //   Vue,
  //   dsn: "https://5e0be4e6f1004cb8b0a942e723d7c8ea@o4504236086525952.ingest.sentry.io/4504236118704128",
  //   // This sets the sample rate to be 10%. You may want this to be 100% while
  //   // in development and sample at a lower rate in production
  //   replaysSessionSampleRate: 0.1,
  //   // If the entire session is not sampled, use the below sample rate to sample
  //   // sessions when an error occurs.
  //   replaysOnErrorSampleRate: 1.0,
  //   tracesSampleRate: 1.0,
  //   integrations: [
  //     new Sentry.Replay({
  //       maskAllText: false,
  //       blockAllMedia: true,
  //     }),
  //     new BrowserTracing({
  //       tracingOrigins: ["*"],
  //       routingInstrumentation: Sentry.vueRouterInstrumentation(router),
  //       tracePropagationTargets: [
  //         "softinya.com",
  //         window.location.hostname,
  //         /^\//,
  //       ],
  //     }),
  //     new Sentry.Integrations.Breadcrumbs({
  //       console: false,
  //     }),
  //   ],
  // });

  try {
    let currentUserFromStorage = ls.get("user");
    let currentUser = JSON.parse(currentUserFromStorage);

    // Sentry.setUser({ email: currentUser.email, id: currentUser.id });
  } catch (e) {
    // Sentry.setUser({ id: "anonymous" });
  }
  // end Sentry init
}

getTranslations().then(({ data }) => {
  let i18n = prepareConfig(data);
  new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App),
  }).$mount("#app");
});
