<template>
  <router-view></router-view>
</template>
<script>
import "@/assets/js/bootstrap-select.min";
import "@/assets/js/bootstrap-switch-button.min";
import "@/assets/js/main";
</script>
<style lang="scss">
@font-face {
  font-family: "unicons";
  src: url("./assets/fonts/unicons.eot");
  src: url("./assets/fonts/unicons.eot") format("embedded-opentype"),
    url("./assets/fonts/unicons.woff") format("woff"),
    url("./assets/fonts/unicons.ttf") format("truetype"),
    url("./assets/fonts/unicons.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Brand-Icons";
  src: url("./assets/fonts/Brand-Icons.ttf?blcw31") format("truetype"),
    url("./assets/fonts/Brand-Icons.woff?blcw31") format("woff"),
    url("./assets/fonts/Brand-Icons.svg?blcw31#Brand-Icons") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Brand-Icons";
  src: url("./assets/fonts/Brand-Icons.ttf?blcw31") format("truetype"),
    url("./assets/fonts/Brand-Icons.woff?blcw31") format("woff"),
    url("./assets/fonts/Brand-Icons.svg?blcw31#Brand-Icons") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Line-Awesome";
  src: url("./assets/fonts/Line-Awesome.ttf?15lwq9") format("truetype"),
    url("./assets/fonts/Line-Awesome.woff?15lwq9") format("woff"),
    url("./assets/fonts/Line-Awesome.svg?15lwq9#Line-Awesome") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Gotham-Rounded";
  src: url("./assets/fonts/Gotham-Rounded-Bold.ttf") format("truetype"),
    url("./assets/fonts/Line-Awesome.woff?15lwq9") format("woff"),
    url("./assets/fonts/Line-Awesome.svg?15lwq9#Line-Awesome") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "unicons";
  src: url("./assets/fonts/unicons.eot");
  src: url("/assets/fonts/unicons.eot") format("embedded-opentype"),
    url("./assets/fonts/unicons.woff") format("woff"),
    url("./assets/fonts/unicons.ttf") format("truetype"),
    url("./assets/fonts/unicons.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Feather-Icons";
  src: url("./assets/fonts/Feather-Icons.ttf?7ncawf") format("truetype"),
    url("./assets/fonts/Feather-Icons.woff?7ncawf") format("woff"),
    url("./assets/fonts/Feather-Icons.svg?7ncawf#Feather-Icons") format("svg");
  font-weight: normal;
  font-style: normal;
}

//@font-face {
//  font-family: 'Material Icons';
//  font-style: normal;
//  font-weight: 400;
//  src: url(./assets/fonts/MaterialIcons-Regular.eot);
//  /* For IE6-8 */
//  src: local('Material Icons'),
//  local('MaterialIcons-Regular'),
//  url(./assets/fonts/MaterialIcons-Regular.woff2) format('woff2'),
//  url(./assets/fonts/MaterialIcons-Regular.woff) format('woff'),
//  url(./assets/fonts/MaterialIcons-Regular.ttf) format('truetype');
//}

// 3rd party plugins css
@import "~bootstrap-vue/dist/bootstrap-vue.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";
@import "~socicon/css/socicon.css";
@import "~@fortawesome/fontawesome-free/css/all.css";
@import "~line-awesome/dist/line-awesome/css/line-awesome.css";
@import "assets/plugins/flaticon/flaticon.css";
@import "assets/plugins/flaticon2/flaticon.css";
@import "assets/plugins/keenthemes-icons/font/ki.css";

@import "assets/css/style.css";
@import "assets/css/night-mode.css";
@import "assets/css/framework.css";
@import "assets/css/bootstrap.css";
@import "assets/css/responsive.css";
@import "assets/css/icons.css";
@import "assets/scss/style.scss";
@import "assets/scss/responsive.scss";
@import "assets/css/bootstrap-switch-button.min.css";
@import "assets/css/bootstrap-tagsinput.css";

// Main demo style scss
//@import "assets/sass/style.vue";

// Check documentation for RTL css
/*@import "assets/css/style.vue.rtl";*/
</style>

<script>
// import { OVERRIDE_LAYOUT_CONFIG } from "@/core/services/store/config.module";
import DashboardLayout from "@/view/layouts/DashboardLayout";
import {
  LOGIN,
  LOGOUT,
  SEND_SMS,
  SET_ERROR,
  GET_USER_WITH_TOKEN,
} from "@/core/services/store/auth.module";
import { GET_ITEMS } from "@/core/services/store/REST.module";

export default {
  name: "MetronicVue",
  components: {
    DashboardLayout,
  },
  computed: {
    userInfo: {
      get: function () {
        return this.authUser;
      },
      set: function (newValue) {},
    },
    user_header: {
      get() {
        if (this.secureLocalStorage("user")) return JSON.parse(this.secureLocalStorage("user"));
        else return []
      },
      set() { },
    },
  },
  beforeMount() {
    if (this.userInfo) {
      document.title = this.userInfo.company_name;
    }
  },
  methods:{
    superAdmin() {
      if (this.user_header === null || !("roles" in this.user_header)) {
        return false;
      }
      if (this.user_header.roles.length > 2) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {
    let subdomain = window.location.host.split(".")[0];
    if(this.superAdmin()){
    }
    else if (
      // (subdomain === "www" || window.location.pathname === "/homepage") &&
      (subdomain === "www" || window.location.hash === "#/homepage") &&
      ((window.location.host !== "www.zorunluegitimler.com")||(window.location.host !== 'www.softinya.com'))&&(!window.location.href.split("/s/")[1])
    ) {
      this.$router.push({ path: "/homepage" });
      // short-url for sms
    } else if (window.location.href.split("/s/")[1]) {
      let shortToken = window.location.href.split("/s/")[1];
      this.$store
        .dispatch(GET_ITEMS, {
          url: "api/s/" + shortToken,
          acceptPromise: false,
        })
        .then((response) => {
          if (response?.status) {
            window.location.href = response.data;
          } else {
            this.$router.push({ path: "/404" });
          }
        })
        .catch((error) => {
          this.$router.push({ path: "/404" });
        });
    } else if (!window.location.href.split("/reset-password/")[1]) {
      try {
        let isAuthenticated = this.$store.getters.isAuthenticated;
        let currentUser = this.$store.getters.currentUser;
        let access_token = new URLSearchParams(window.location.search).get( "token");
        if (access_token) {
      //     let type = new URLSearchParams(window.location.search).get( "type");
      //     let id = new URLSearchParams(window.location.search).get( "id");
      //     if(type==='assignment'&&id){
      // this.$router.push({ name: "user.assignment-detail", params: { id: id } });
      //     }else if(type==='education'&&id){
      //       this.$router.push({ name: "user.education-detail", params: { id: id } });
      //     }else if(type==='education-category'){
      //       this.$router.push({ path: "/education-category" });}
      //   else{
          this.$router.push({ path: "/dashboard" });
        // }
        } else if (!isAuthenticated && !currentUser) {
          // this.$router.push({'name': 'login'});

          this.$router.push({
            name: "login",
            query: { redirect: self.$route.fullPath },
          });
        // } else if (window.location.hash === "/" && subdomain !== "www") {
        } else if (window.location.hash === "#/" && subdomain !== "www") {
          this.$router.push({ path: "/dashboard" });
        } else if (!isAuthenticated) {
          this.$router.push({ path: "/login" });
        }
      } catch (e) {
        // window.localStorage.clear();
        this.$router.push({ name: "login" });
        this.$router.push({
          name: "login",
          query: { redirect: self.$route.fullPath },
        });
      }
      if (!window.localStorage.getItem("redirect_url")) {
        let redirectUrl=self.location.hash.substring(1);
          window.localStorage.setItem("redirect_url",redirectUrl);
        }
           if(window.localStorage.getItem("redirect_url")==='/'||window.localStorage.getItem("redirect_url")==='/login')
          window.localStorage.setItem("redirect_url",'/dashboard');
    }
    /**
     * this is to override the layout config using saved data from localStorage
     * remove this to use config only from static json (@/core/config/layout.config.json)
     */
    // this.$store.dispatch(OVERRIDE_LAYOUT_CONFIG);
  },
};
</script>
<style>
body {
  overflow-y: scroll !important;
  height: 100vh !important;
}

/* :root {
  --backgroundColor: #ff11007e;
  --white: #121110;
} */
</style>
