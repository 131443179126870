import ApiService from "@/core/services/api.service";
import router from '@/router';
import {responseError} from '@/core/mixin/shared'
import querystring from 'querystring';


export const BASE_URL = 'api/notifications';
export const MODULE_NAME = 'NOTIFICATION';
export const LOADING = `/LOADING`;
export const ERROR = `/ERROR`;
export const SUCCESS = `/SUCCESS`;
export const ITEMS = `/ITEMS`;
export const CURRENT_ITEM = `/CURRENT_ITEM`;
export const COUNT = `/COUNT`;

export const SET_LOADING = `/M/LOADING`;
export const SET_ERROR = `/M/ERROR`;
export const SET_SUCCESS = `/M/SUCCESS`;
export const SET_ITEMS = `/M/ITEMS`;
export const SET_COUNT = `/M/COUNT`;
export const SET_CURRENT_ITEM = `/M/CURRENT_ITEM`;
export const RESET_VALUES = `/M/RESET_VALUES`;

// ACTIONS
export const GET_ITEMS = `/GET_ITEMS`;
export const GET_ITEM_DETAIL_BY_ID = `/GET_ITEM_DETAIL_BY_ID`;
export const GET_COUNT = `/GET_COUNT`;
export const CREATE_ITEM = `/CREATE_ITEM`;
export const UPDATE_ITEM_BY_ID = `/UPDATE_ITEM_BY_ID`;
export const DELETE_ITEM_BY_ID = `/DELETE_ITEM_BY_ID`;

const state = {
    items: [],
    currentItem: null,
    notification_count: null,
    loading: false,
    error: null,
    success: null,
};

const getters = {
    [LOADING]: (state) => {
        return state.loading;
    },
    [ERROR]: (state) => {
        return state.error;
    },
    [SUCCESS]: (state) => {
        return state.success;
    },
    [ITEMS]: (state) => {
        return state.items;
    },
    [CURRENT_ITEM]: (state) => {
        return state.currentItem;
    },
    [COUNT]: (state) => {
        return state.notification_count;
    },
};

const mutations = {
    [SET_LOADING]: (state, payload) => {
        state.loading = payload;
    },
    [SET_ERROR]: (state, payload) => {
        state.error = payload ? responseError(payload) : null;
    },
    [SET_SUCCESS]: (state, payload) => {
        state.success = payload;
    },
    [SET_ITEMS]: (state, payload) => {
        state.items = payload;
    },
    [SET_CURRENT_ITEM]: (state, payload) => {
        state.currentItem = payload;
    },
    [SET_COUNT]: (state, payload) => {
        state.notification_count = payload;
    },
    [RESET_VALUES]: (state) => {
        state.success = null;
        state.error = null;
        state.loading = false;
        state.items = [];
        state.currentItem = null;
    },
};

const actions = {
    [GET_COUNT]: (context, payload) => {
        let url = payload.url || BASE_URL;
        let filters = payload.filters;
        if ('showLoading' in payload && payload.showLoading) {
            context.commit(SET_LOADING, true);
        }
        return ApiService.get(`${url}?` + querystring.stringify({...filters,onlyCount:true}))
            .then((response) => {
                if (!payload.acceptPromise) {
                    context.commit(SET_COUNT, response.data);
                }
                if ('showLoading' in payload && payload.showLoading) {
                    context.commit(SET_LOADING, false);
                }
                context.commit(SET_ERROR, null);
                return {status: true, data: response.data};
            })
            .catch((error) => {
                context.commit(SET_ERROR, error);
                context.commit(SET_LOADING, false);
                return {status: false, error: error};
            });
    },
    [GET_ITEMS]: (context, payload) => {
        let url = payload.url || BASE_URL;
        let filters = payload.filters;
        if ('showLoading' in payload && payload.showLoading) {
            context.commit(SET_LOADING, true);
        }
        return ApiService.get(`${url}?` + querystring.stringify(filters))
            .then((response) => {
                let formattedItems = [];

                if (!payload.acceptPromise) {

                    if (typeof response.data !== "undefined") {
                        response.data.forEach((item) => {
                            if (item.type == 1) {
                                item.parsed_data = JSON.parse(item.data);
                                formattedItems.push(item);
                            } else {
                                item.parsed_data = {
                                    apiUrl: null,
                                    message: item.name,
                                    text: item.text,
                                    webUrl: null,
                                };
                                formattedItems.push(item);
                            }
                        });

                    }



                    context.commit(SET_ITEMS,formattedItems);
                }
                if ('showLoading' in payload && payload.showLoading) {
                    context.commit(SET_LOADING, false);
                }
                context.commit(SET_ERROR, null);
                return {status: true, data: formattedItems};
            })
            .catch((error) => {
                context.commit(SET_ERROR, error);
                context.commit(SET_LOADING, false);
                return {status: false, error: error};
            });
    },
    [GET_ITEM_DETAIL_BY_ID]: (context, payload) => {
        let url = payload.url || BASE_URL;
        let filters = payload.filters;
        context.commit(SET_LOADING, true);
        return ApiService.get(`${url}?` + querystring.stringify(filters))
            .then((response) => {
                if (!payload.acceptPromise) {
                    context.commit(SET_CURRENT_ITEM, response.data);
                }
                context.commit(SET_LOADING, false);
                context.commit(SET_ERROR, null);
                return {status: true, data: response.data};

            }).catch((error) => {
                context.commit(SET_ERROR, error);
                context.commit(SET_LOADING, false);
                router.push({
                    name: payload.redirectRouteOnNotFound,
                    params: {
                        error: 'general.couldnt_found_item_with_given_id'
                    }
                });
            });
    },
    [CREATE_ITEM]: (context, payload) => {
        let url = payload.url || BASE_URL;
        let contents = payload.contents;
        context.commit(SET_LOADING, true);
        return ApiService.post(url, contents).then((response) => {
            if (!payload.acceptPromise) {
                context.commit(SET_CURRENT_ITEM, null);
                context.commit(SET_ITEMS, []);
                router.push({name: payload.redirectRouteName, params: {success: payload.successMessage}});
            }
            context.commit(SET_LOADING, false);
            context.commit(SET_ERROR, null);
            return {status: true, data: response.data};

        }).catch((error) => {
            context.commit(SET_ERROR, error);
            context.commit(SET_LOADING, false);
            return {
                status: false,
                error: error.response.data ? error.response.data : error,
                code: error.response.status ? error.response.status : 500
            };
        });
    },
    [UPDATE_ITEM_BY_ID]: (context, payload) => {
        let url = payload.url || BASE_URL;
        let contents = payload.contents;

        if (!payload.hasOwnProperty('noLoading')) {
            context.commit(SET_LOADING, true);
        }
        return ApiService.post(url, contents)
            .then((response) => {
                if (payload.redirectRouteNamen != undefined) {
                    context.commit(SET_LOADING, false);
                    context.commit(SET_CURRENT_ITEM, null);
                    context.commit(SET_ITEMS, []);
                    router.push({name: payload.redirectRouteName, params: {success: payload.successMessage}});
                }
                context.commit(SET_LOADING, false);
                context.commit(SET_ERROR, null);
                return {status: true, data: response.data};
            }).catch((error) => {
                context.commit(SET_ERROR, error);
                context.commit(SET_LOADING, false);
                return {
                    status: false,
                    error: error.response.data ? error.response.data : error,
                    code: error.response.status ? error.response.status : 500
                };
            });
    },
    [DELETE_ITEM_BY_ID]: (context, payload) => {
        let url = payload.url || BASE_URL;
        let id = payload.id;
        context.commit(SET_LOADING, true);
        return ApiService.delete(url).then((_) => {
            if (context.state.items.data !== undefined) {
                for (let i = 0; i < context.state.items.data.length; i++) {
                    let currentItem = context.state.items.data[i];
                    if (Number(id) === Number(currentItem.id)) {
                        context.state.items.data.splice(i, 1);
                        break;
                    }
                }
            } else {
                for (let i = 0; i < context.state.items.length; i++) {
                    if (Number(id) === Number(context.state.items[i].id)) {
                        context.state.items.splice(i, 1);
                        break;
                    }
                }
            }
            context.commit(SET_ERROR, null);
            context.commit(SET_LOADING, false);

        }).catch((error) => {
            context.commit(SET_ERROR, error);
            context.commit(SET_LOADING, false);
            return {
                status: false,
                error: error.response.data ? error.response.data : error,
                code: error.response.status ? error.response.status : 500
            };
        });
    }

};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
