// GETTERS


export const TITLE = 'EDCUATIONDETAIL/TITLE'
export const SUB_TITLE = 'EDCUATIONDETAIL/SUB_TITLE'
export const START_DATE = 'EDCUATIONDETAIL/START_DATE'
export const END_DATE = 'EDCUATIONDETAIL/END_DATE'
export const FINISHED_RATE = 'EDCUATIONDETAIL/FINISHED_RATE'
export const IMAGE = 'EDCUATIONDETAIL/IMAGE'
export const DESCRIPTION_TITLE = 'EDCUATIONDETAIL/DESCRIPTION_TITLE'
export const DESCRIPTION = 'EDCUATIONDETAIL/DESCRIPTION'
export const PERSON_COMPLETED_EDUCATION = 'EDCUATIONDETAIL/PERSON_COMPLED_EDUCATION'
export const EXPERIENCE_SHARES_COUNT = 'EDCUATIONDETAIL/EXPERIENCE_SHARES_COUNT'

export const LOADING = 'EDCUATIONDETAIL/LOADING';
export const MESSAGE = 'EDCUATIONDETAIL/MESSAGE';


// MUTATIONS
export const SET_MESSAGE = 'EDCUATIONDETAIL/M/MESSAGE';
export const SET_LOADING = 'EDCUATIONDETAIL/M/LOADING';

// ACTIONS
export const SAVE_MESSAGE = 'TEST/A/SAVE_MESSAGE';


const state = {
    title: "İş Sağlığı Ve Güvenliği",
    sub_title: "Zorunlu Eğitimler",
    start_date: '21.09.2020',
    end_date: '22.10.2020',
    description: '',
    person_completed_education: '32',
    image: '/images/course/1.png',
    description_title: 'Eğitim Hakkında',
    finished_rate: 65,
    message: '',
    loading: false,
    experience_shares_count: 24,

}

const getters = {

    [TITLE]: (state) => {
        return state.title;

    },
    [SUB_TITLE]: (state) => {
        return state.sub_title;
    },
    [START_DATE]: (state) => {
        return state.start_date;
    },
    [END_DATE]: (state) => {
        return state.end_date;
    },
    [PERSON_COMPLETED_EDUCATION]: (state) => {
        return state.person_completed_education;
    },
    [IMAGE]: (state) => {
        return state.image;
    },
    [DESCRIPTION_TITLE]: (state) => {
        return state.description_title;
    },
    [DESCRIPTION]: (state) => {
        return state.description;
    },
    [FINISHED_RATE]: (state) => {
        return state.finished_rate;
    },
    [LOADING]: (state) => {
        return state.loading;
    },
    [MESSAGE]: (state) => {
        return state.message;
    },
   [EXPERIENCE_SHARES_COUNT]: (state) =>{

        return state.experience_shares_count;
   }
};

const mutations = {

    [SET_MESSAGE]: (state, payload) => {
        state.message = payload;

    },
    [SET_LOADING]: (state, payload) => {
        state.loading = payload;
    },
};

const actions = {
    [SAVE_MESSAGE]: (context) => {

        context.commit(SET_LOADING, true);
        context.commit(SET_MESSAGE);
        context.commit(SET_LOADING, false);
    }
};

export default {
    state,
    getters,
    mutations,
    actions,
}


